@import url('https://fonts.googleapis.com/css2?family=Ubuntu+Condensed&family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
/*@import url('https://fonts.cdnfonts.com/css/montreal');*/
@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bg-color {
  background-color: #071423;
}

.shadow-inner-terr {

  --tw-shadow: inset 0 25px 10px -10px rgba(0, 0, 0, .5);
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.drop-shadow-icons,
.drop-shadow-buttons,
.drop-shadow-div {

  --tw-drop-shadow: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(7px 10px 5px rgb(0 0 0 / 0.5));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);

}

.text-ubuntu {
  /*font-family: 'Ubuntu', sans-serif;*/
  /*font-family: 'Ubuntu Condensed', sans-serif;*/
  font-family: 'Ubuntu Mono', monospace;
}

.drop-shadow-bottom-terr {
  --tw-drop-shadow: drop-shadow(0px 13px 9px rgb(0 0 0 / 0.5));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

/*.text-montreal {
  font-family: 'Montreal', sans-serif;
}*/

.text-helvetica {
  font-family: 'Helvetica Neue', sans-serif;
  font-style: normal
}

.text-roboto {
  font-family: 'Roboto', sans-serif;
}

.pill-blue-bgcolor {
  background-color: #2f4e7a;
}

.bg-color-header {
  background: rgb(7, 20, 35);
  background: linear-gradient(150deg, rgba(7, 20, 35, 1) 0%, rgba(101, 198, 237, 1) 100%);
}

/* CSS */
.button-79 {
  backface-visibility: hidden;
  border: 0;
  border-radius: .375rem;
  box-sizing: border-box;
  color: #fff;
  background: rgb(57, 115, 176);
  background: linear-gradient(165deg, rgba(57, 115, 176, 1) 0%, rgba(60, 137, 211, 1) 49%, rgba(101, 198, 237, 1) 77%);
  cursor: pointer;
  display: inline-block;
  font-family: Circular, Helvetica, sans-serif;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: -.01em;
  line-height: 1.3;
  position: relative;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.feature-icon img {
  transform: translateZ(0) scale(1);
  transition: transform .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-cc-padding {
  padding: 0.5em 0.75rem;
}

.button-79:disabled {
  color: #787878;
  cursor: auto;
}

.button-79:not(:disabled):hover,
.feature-icon img:not(:disabled):hover {
  transform: scale(1.05);
}

.button-79:not(:disabled):hover:active,
.feature-icon img:not(:disabled):hover:active {
  transform: scale(1.05) translateY(.125rem);
}

.button-79:focus .feature-icon img:focus {
  outline: 0 solid transparent;
}

.button-79:focus:before,
.feature-icon img:before {
  border-width: .125rem;
  /*content: "";*/
  left: calc(-1*.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1*.375rem);
  transition: border-radius;
  user-select: none;
}

.button-79:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-79:not(:disabled):active {
  transform: translateY(.125rem);
}



body::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: none;
}

body::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(57, 115, 176);
  background: linear-gradient(0deg, rgba(57, 115, 176, 1) 25%, rgba(101, 198, 237, 1) 50%, rgba(60, 137, 211, 1) 75%);
}

body:not(:-webkit-scrollbar-thumb) {
  padding-right: 0;
  /* Remove padding when scrollbar is not visible */
}

.text-stroke {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1.5px;
  text-stroke-width: 1.5px;
  color: black;
}


.text-stroke-unset {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1.5px;
  text-stroke-width: 1.5px;
  color: black;
}

@media (max-width: 639px) {
  .text-stroke-unset {
    -webkit-text-fill-color: unset;
    -webkit-text-stroke: 0 transparent;
    text-stroke: 0 transparent;
    color: unset;
  }
}